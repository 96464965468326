import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { stringify } from 'query-string';
import { PAGE_SIZE, PAGE_SIZE_60 } from 'plp/constants';
import { PAGE_SIZE_SRP } from 'srp/constants';
import { ArrowButton, ArrowDirections } from './components/ArrowButton/arrowButton';
import './pagination.scss';

const scrollTo = () => {
  window.scrollTo(0, 0);
};

const resetRecentSearchAnalyticsFlag = () => {
  if (window.utag_data_dt && window.utag_data_dt.recently_searched) {
    delete window.utag_data_dt.recently_searched;
  }
};

const Pagination = ({
  plpCurrentPage,
  pathname = '',
  query,
  enforceMaxPageToggle = 999,
  maxPageForAll,
  pageName,
  isMobile,
  isTablet,
  mobileFetchSize,
  plpTotalProducts,
  srpCurrentPage,
  srpTotalProducts,
  srpLazyLoadToggle,
  lazyLoadDisabledConfigFetchSize,
  srpSlsToggle,
  srp120Toggle,
  exposedPaginationToggle,
  isListEnd,
  isPLP60ToggleEnabled,
}) => {
  let currentPage;
  let totalProducts;
  if (pageName === 'SRP') {
    currentPage = srpCurrentPage;
    totalProducts = srpTotalProducts;
  } else {
    currentPage = plpCurrentPage;
    totalProducts = plpTotalProducts;
  }

  let totalPages = 0;
  let calculatedDefaultFetchSize;
  const FINAL_PAGE_SIZE = isPLP60ToggleEnabled ? PAGE_SIZE_60 : PAGE_SIZE;
  const calculatedMobileFetchSize = Math.ceil(totalProducts / mobileFetchSize);
  totalPages = Math.ceil(totalProducts / mobileFetchSize);
  const lazyLoadDisabledPages = Math.ceil(totalProducts / lazyLoadDisabledConfigFetchSize);
  totalPages = Math.ceil(totalProducts / FINAL_PAGE_SIZE);
  const isMobileAndNotTablet = !isTablet && isMobile;
  const isSRP = (pageName === 'SRP');

  if (isSRP && srpSlsToggle) {
    let pageSize = PAGE_SIZE_SRP;
    if (srp120Toggle && !isMobileAndNotTablet) {
      pageSize = 120;
    }
    calculatedDefaultFetchSize = Math.ceil(totalProducts / pageSize);
  } else {
    calculatedDefaultFetchSize = Math.ceil(totalProducts / FINAL_PAGE_SIZE);
  }
  if ((isSRP && !srpLazyLoadToggle)) {
    totalPages = lazyLoadDisabledPages;
  } else if (isMobileAndNotTablet) {
    if (isSRP) {
      totalPages = calculatedMobileFetchSize;
    } else {
      totalPages = calculatedDefaultFetchSize;
    }
  } else {
    totalPages = calculatedDefaultFetchSize;
  }

  if (!isPLP60ToggleEnabled && exposedPaginationToggle && pageName === 'PLP' && totalPages > 82) {
    totalPages = 82;
  }
  if (isPLP60ToggleEnabled  && exposedPaginationToggle && pageName === 'PLP' && totalPages > 164) {
    totalPages = 164;
  }

  const changePage = (page) => {
    let nextPage = page;
    if (nextPage < 1) nextPage = 1;
    if (nextPage > totalPages) nextPage = totalPages;
    return {
      pathname,
      search: `?${stringify({
        ...query,
        page: nextPage,
      })}`,
    };
  };
  const prevRoute = () => {
    const oldPage = parseInt(currentPage, 10) || 1;
    return changePage(oldPage - 1);
  };
  const nextRoute = () => {
    const oldPage = parseInt(currentPage, 10) || 1;
    return changePage(oldPage + 1);
  };

  const prevVisible = currentPage > 1;
  let prevArrow = '';
  if (prevVisible) {
    prevArrow = (
      <ArrowButton
        ariaLabel="Previous"
        arrowDirection={ArrowDirections.LEFT}
        label="Prev"
        to={prevRoute()}
      />
    );
  }

  let nextVisible = currentPage < totalPages;
  if (pageName !== 'SRP' && enforceMaxPageToggle && currentPage === maxPageForAll) {
    nextVisible = false;
  }
  let nextArrow = '';
  if (nextVisible) {
    nextArrow = (
      <ArrowButton
        ariaLabel="Next"
        arrowDirection={ArrowDirections.RIGHT}
        label="Next"
        to={nextRoute()}
      />
    );
  }

  const getPageRange = (currentPage, totalPages) => {
    const pageRange = [];
    const MAX_PAGES_DISPLAYED = 4;

    if (totalPages <= MAX_PAGES_DISPLAYED) {
      for (let i = 1; i <= totalPages; i++) {
        pageRange.push(i);
      }
    } else {
  
      const leftBoundary = currentPage === 1 ? 1 : Math.max(1, currentPage - (currentPage === totalPages ? 3 : 1));
      const rightBoundary = currentPage === totalPages ? totalPages : Math.min(totalPages, leftBoundary + (currentPage === 1 ? 3 : 2));
    
      for (let pageNumber = leftBoundary; pageNumber <= rightBoundary; pageNumber++) {
        pageRange.push(pageNumber);
      }
    
      if (leftBoundary > 1) {
        if (leftBoundary > 2) {
          pageRange.unshift('...');
        }
        pageRange.unshift(1);
      }
    
      if (rightBoundary < totalPages) {
        if (rightBoundary < totalPages - 1) {
          pageRange.push('...');
        }
        pageRange.push(totalPages);
      }
    }
    return pageRange;
  };
  const pageRange = getPageRange(currentPage, totalPages);

  return totalPages > 1 && (
    <nav className={`pagination ${exposedPaginationToggle && isListEnd && 'pagination__exposed'}`} aria-label="Pagination" onClick={() => { resetRecentSearchAnalyticsFlag(); }}>
      <span onClick={scrollTo}>
        {prevArrow}
      </span>
      {
        exposedPaginationToggle && isListEnd ?
          <div className="pagination-pages">
            {pageRange.map((page, index) => (
              <Link key={index} className={page === currentPage ? 'pagination-pages--current-page' : ''} to={page === '...' ? '' : changePage(page)} >
                {page}
              </Link>
            ))}
          </div> :
          <span aria-label={`Page ${currentPage} of ${totalPages}`} className="pagination-pages">
            <span className="pagination-pages--current-page">{currentPage}</span>
            {' of '}
            {totalPages}
          </span>
      }
      <span onClick={scrollTo}>
        {nextArrow}
      </span>
    </nav>
  );
};

const mapStateToProps = (state, ownProps) => {
  const plpCurrentPage = get(state, 'productListPage.products.currentPage', 1);
  const isMobile = get(state, 'session.deviceType.isMobile', false);
  const isTablet = get(state, 'session.deviceType.isTablet', false);
  const mobileFetchSize = get(state, 'mobileConfig.pagination.fetchSize', 60);
  const lazyLoadDisabledConfigFetchSize = get(state, 'lazyLoadDisabledConfig.pagination.fetchSize', 60);
  const plpTotalProducts = get(state, 'productListPage.products.total', 0);

  return {
    plpCurrentPage,
    enforceMaxPageToggle: state.toggles.ENFORCE_MAX_PAGE,
    maxPageForAll: state.productCatalog.maxPageForAll,
    pageName: ownProps.source,
    ...state.page.location,
    isMobile,
    isTablet,
    mobileFetchSize,
    plpTotalProducts,
    srpCurrentPage: get(state, 'srp.currentPage', 1),
    srpTotalProducts: get(state, 'srp.search.filteredTotal', 0),
    srpLazyLoadToggle: state.toggles.SRP_IMAGE_LAZY_LOAD,
    lazyLoadDisabledConfigFetchSize,
    srpSlsToggle: get(state, 'toggles.SRP_SLS', false),
    srp120Toggle: get(state, 'toggles.SRP_120', false),
    exposedPaginationToggle: get(state, 'toggles.EXPOSE_PAGINATION', false),
    isListEnd: get(ownProps, 'isListEnd', false),
    isPLP60ToggleEnabled: (get(state, 'toggles.AB_TEST_PLP_60', false) && get(state, `abTestsOpt.tl357.variation`, 'a') === 'b') ,
  };
};

export default connect(mapStateToProps)(Pagination);
