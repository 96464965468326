import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { InView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import reverse from 'lodash/reverse';
import flattenDeep from 'lodash/flattenDeep';
import cloneDeep from 'lodash/cloneDeep';
import times from 'lodash/times';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import reactCookie from 'react-cookie';
import Loadable from 'react-loadable';
import { createSelector } from 'reselect';
import { getECMRequest } from 'ecm/actions/actions-ecmcontent';
import { shouldLoad } from 'universal/http-client';
import { PRODUCTS_NOT_AVAILABLE_MESSAGE, PAGE_SIZE, IN_STORE, PAGE_SIZE_60 } from 'plp/constants';
import { markStart } from 'client-utils/utilities-performance';
import { getParams, updateParams, updateParamsForSortBy } from 'client-utils/utilities-router';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';
import { seoSelectedFilter } from 'client-utils/utilities-seoFacetUtag';
import { StorageHandler } from 'shared/Storage/StorageHandler';
import {
  setMetaInfoWithHTMLMetaTag,
  setTitle,
  setTitleOverride,
  setMetaDescriptionCategory,
  setFullMetaDescription,
  ABTEST_MOBILE_ALT_IMAGES,
  ABTEST_PLP_SEO_REC,
  ABTEST_ADDITIONAL_IMAGES,
  WEB_ADDITIONAL_IMAGES_ALT_SHOTS,
} from 'shared/actions/actions-page';
import { getCategoryNoResultsContent, getProductListMetaData, matchFristAvalibleShot } from 'plp/components/ProductListPage/actions';
import { ECMSlots } from 'ecm/constants';
import { RecentSizesStorageHandler } from 'shared/Storage/RecentSizesStorageHandler';
import { unescape } from 'he';
import { renderCMSContent, placementExists, isGlobalBottomBanner } from 'client/utilities/utilities-cms';
import { isCMSPromoAvailable, isCMSDynamicPromoAvailable, getDynamicPromoTileSpots } from 'client-utils/isCMSPromoAvailable';
import { isDesktop, isMobile, getBrowserInfo } from 'client-utils/utilities-page';
import CMSPromoTile from 'cms/components/promoTile/promoTile';
import ProductRecs from 'cms/components/productRecs/productRecs';
import PromoTile from './components/PromoTile/promoTile';
import ConnectedEnhancedProduct, { ProductLoadingPlaceholder } from './components/Product/product-enhancement';
import ConnectedProductListHeader from './components/ProductListHeader/ProductListHeader';
import StickyScroll from './components/atoms/StickyScroll/StickyScroll';
import Pagination from './components/Pagination/pagination';
import {
  handleClearAllFilters,
  handleURLUpdate,
} from '../FacetedLeftNav/actions';
import Seo from '../Seo/seo';
import RelatedItems from '../RelatedItems/relatedItems';
import './productList.scss';


const renderRecentlyViewedProducts = Loadable({
  loader: () => import('./components/RelatedItems/renderRecentlyViewedProducts'),
  loading: () => false,
});

export const RenderRelatedCategories = Loadable({
  loader: () => import('./components/RelatedItems/renderRelatedCategories'),
  loading: () => false,
});

export const RenderRelatedProducts = Loadable({
  loader: () => import('./components/RelatedItems/renderRelatedProducts'),
  loading: () => false,
});


export function getAlternateImage(product) {
  const LAImage = get(product, 'media.alternate.l.medium.url');
  const AAImage = get(product, 'media.alternate.a.medium.url');
  return LAImage || AAImage;
}

const isFilterApplied = (facetsSelected, seoFacetsMap, applicableFilters) => {
  if(isString(facetsSelected) || typeof facetsSelected !== "object"){
    return false;
  }
  let filerKeyList = []
  seoFacetsMap.map((facet)=>{
    Object.keys(facet).map((seoKey)=>{
      filerKeyList.push(applicableFilters.find((filter) => filter.displayText === facet[seoKey]?.displayText)?.filterKey?.toLocaleLowerCase());
    })
  })
  let currList =[];
  Object.keys(facetsSelected).forEach((curr) => {
    if (curr !== 'In Store' && facetsSelected[curr]?.length == 1) {
      currList.push(curr.toLocaleLowerCase());
    }
  });
  return currList.length> 0 &&  currList.filter(Set.prototype.has, new Set(filerKeyList)).length === currList.length;
};
export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      node: null,
      isFiltered: false,
      showAltAnimation: false,
      enableLazyLoadingForBrowser: true,
    };
    if (props.storageHandler) {
      this.storageHandler = props.storageHandler;
    } else {
      this.storageHandler = new StorageHandler();
    }
    if (props.recentSizesStorageHandler) {
      this.recentSizesStorageHandler = props.recentSizesStorageHandler;
    } else {
      this.recentSizesStorageHandler = new RecentSizesStorageHandler();
    }
    this.getProductsForSelectedSortOption = this.getProductsForSelectedSortOption.bind(this);
    this.getProductsForSelectedFilters = this.getProductsForSelectedFilters.bind(this);
    this.clearSelectedFacets = this.clearSelectedFacets.bind(this);
    this.ecmPrefetchPlpBanner = this.ecmPrefetchPlpBanner.bind(this);
    this.renderSeoPageTitleAndMetaInfo = this.renderSeoPageTitleAndMetaInfo.bind(this);
    // eslint-disable-next-line max-len
    this.getProductListWithInsertedPromoTilesFromCRP = this.getProductListWithInsertedPromoTilesFromCRP.bind(this);
    this.renderECMPromoTile = this.renderECMPromoTile.bind(this);
    this.getMetaDescriptionContent = this.getMetaDescriptionContent.bind(this);
    this.shouldEnableLazyLoadingForBrowser = this.shouldEnableLazyLoadingForBrowser.bind(this);
    this.isDeviceTypeTablet = this.isDeviceTypeTablet.bind(this);
  }

  componentDidMount() {
    if(!this.state.showAltAnimation && this.props.altImageAbTestToggle && this.props.isDevicetypeMobilePhone){
      this.setState({ showAltAnimation: true });
    }

    if (this.isProductListEmpty() && !this.hasFilterOptions()) {
      this.props.getCategoryNoResultsContent();
    }
    if (this.props.productMetadataToggle && this.props.callMetadataService) {
      this.getProductListMetadata(this.props.productList);
    }

    if (window?.navigator?.userAgent) {
      const {browserName, version} = getBrowserInfo(window.navigator.userAgent);
      this.setState({enableLazyLoadingForBrowser : this.shouldEnableLazyLoadingForBrowser(browserName, version)})   
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.productlistLoading === true && !this.props.skeletonLoadingToggle) {
      return false;
    }

    if(this.state.showAltAnimation){
      this.setState({ showAltAnimation: false });
    }

    return true;
  }

  getProductListMetadata(productList) {
    const listOfProductIds = productList
      .map((item) => item.id);
    const listOfChildProducts = productList
      .filter((item) => (item.grp))
      .map((item) => (item.chld))
      .map((childItem) => childItem.map((subChildItem) => subChildItem.id));
    const flatProductIds = flattenDeep([listOfProductIds, listOfChildProducts]);

    this.props.getProductListMetaData(flatProductIds);
  }

  getProductsForSelectedSortOption(sortBy) {
    markStart('PLP_TimeToApplySortBy');
    const { router = {}, seoToggle } = this.props;
    if (seoToggle) {
      updateParamsForSortBy(router, { sortBy, page: 1 })
    } else {
      updateParams(router, { sortBy, page: 1, seoToggle });
    }
  }

  getExistingFilters() {
    const { filterOptions: existingFiltersString } = getParams(this.props.location);
    try {
      return isEmpty(existingFiltersString) ? {} : JSON.parse(existingFiltersString);
    } catch (err) {
      return {};
    }
  }

  getProductRecommendationsResult() {
    return (
      <span className={classnames('full-width-container')}>
        <InView
          rootMargin={"1400px"} 
          triggerOnce >
          {({ inView, ref }) => {
            return (
              <div ref={ref} className={classnames('full-width-container')}>
                {inView && <ProductRecs />}
              </div>
            );
          }}
        </InView>
      </span>
    )
  }

  getProductsForSelectedFilters(filterOptions) {
    markStart('PLP_TimeToApplyFilter');
    const existingFilters = this.getExistingFilters();
    const filters = Object.assign(this.getInitialFilters(),
      existingFilters, filterOptions);
    this.updateParamsForFilters(filters);
    this.saveRecentSizesInLocalStorage(existingFilters, filterOptions);
  }

  getInitialFilters() {
    return reduce(this.props.applicableFilters,
      (result, filter) => Object.assign({}, result, { [filter.filterKey]: [] }), {});
  }

  getNoOfItemsLeftInProductIfStockLevelFallsBelowThreshold(product) {
    const { breadcrumbs, onlyXLeftThresholds } = this.props;
    const topSiloCategoryId = !isEmpty(breadcrumbs) && breadcrumbs[0].id;
    const thresholdValue = onlyXLeftThresholds && onlyXLeftThresholds[topSiloCategoryId];
    const onlyXItemsLeftStockLevel = product.xleft;
    if (onlyXItemsLeftStockLevel > 0 && onlyXItemsLeftStockLevel <= thresholdValue) {
      return onlyXItemsLeftStockLevel;
    }
    return null;
  }

  getSeoContent() {
    const { seo, seoCopyToggle, isDomestic } = this.props;
    if (!isEmpty(get(seo, 'title')) && !isEmpty(get(seo, 'content'))) {
      return (
        <Seo
          title={seo.title}
          content={seo.content}
          margin={isEmpty(seo.relatedContent) ? 26 : 46}
          seoCopyToggle={isDomestic && seoCopyToggle}
        />
      );
    }
    return null;
  }

  getProductListWithPromoTiles(productListMap, isCMSPromoTile) {
    const sortedPromoTiles = this.sortPromoTiles();

    return sortedPromoTiles[3]
      ? this.getProductListWithInsertedPromoTilesFromCRP(productListMap, isCMSPromoTile)
      : this.getProductListWithInsertedPromoTiles(productListMap);
  }

 isDeviceTypeTablet() {
    if(typeof window !== "undefined") {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      const aspectRatio = width / height;

      if (isTouchDevice && width >= 600 && width <= 1024 && aspectRatio < 1.6) {
          return true;
      }
    }
    return false;
  }

  getProductListWithInsertedPromoTiles(productListMap) {
    const {page, isTablet, showProductRecs, cmsContent} = this.props;
    const layouts = cmsContent?.fields?.l1Layouts || [];
    const stylyzeRecs = layouts.filter(layout => layout.fields?.placement === 'StylyzeRecs');
    const sortedPromoTiles = this.sortPromoTiles();
    const productListMapWithPromoTiles = Object.assign([], productListMap);
    const isEligible = isDesktop();
    let prodLength;

    if (page === 1 && sortedPromoTiles[0]) {
      prodLength = isEligible ? 8 : 6;
      if (productListMap.length > prodLength) {
        productListMapWithPromoTiles.splice(prodLength, 0, (
          <PromoTile
            key={1}
            index={1}
            ecmSlot={sortedPromoTiles[0]}
          />
        ));
      }
      prodLength = isEligible ? 47 : 42;
      const spliceLength = isEligible ? 47 : 43;

      if (productListMap.length > prodLength && sortedPromoTiles[1]) {
        productListMapWithPromoTiles.splice(spliceLength, 0, (
          <PromoTile
            key={2}
            index={2}
            ecmSlot={sortedPromoTiles[1]}
          />
        ));
      }

      let keyIdx = 3;
      if (isEligible && !isMobile()) {
        prodLength = 79;
        if (productListMap.length >= prodLength && sortedPromoTiles[0]) {
          productListMapWithPromoTiles.splice(prodLength, 0, (
            <PromoTile
              key={keyIdx}
              index={keyIdx}
              ecmSlot={sortedPromoTiles[0]}
            />
          ));
          keyIdx += 1;
        }
      }

      prodLength = isEligible ? 104 : 102;
      if (!isMobile() && productListMap.length > prodLength && sortedPromoTiles[2]) {
        productListMapWithPromoTiles.splice(104, 0, (
          <PromoTile
            key={keyIdx}
            index={keyIdx}
            ecmSlot={sortedPromoTiles[2]}
          />
        ));
      }
    }
    if (showProductRecs  && this.props.productList.length > 28) {
      const productRecsPosition = isTablet || this.isDeviceTypeTablet() ? 21 : 28;
      productListMapWithPromoTiles.splice(productRecsPosition, 0, (this.getProductRecommendationsResult()))
    }

    return productListMapWithPromoTiles;
  }

  getTotalPagesCount = () => {
    const { total, exposedPaginationToggle, isPLP60ToggleEnabled } = this.props;
    let totalPageCount = Math.ceil(total / PAGE_SIZE) || 0;
    if(exposedPaginationToggle && totalPageCount > 82){
      totalPageCount = 82;
    }
    if(isPLP60ToggleEnabled){
      totalPageCount = Math.ceil(total / PAGE_SIZE_60) || 0;
      if(exposedPaginationToggle && totalPageCount > 164){
        totalPageCount = 164;
      }
    }
    return totalPageCount;
  }

  getProductListWithInsertedPromoTilesFromCRP(productListMap, isCMSPromoTile) {
    const {page, cmsEntries, isTablet, showProductRecs, cmsContent} = this.props;
    const layouts = cmsContent?.fields?.l1Layouts || [];
    const stylyzeRecs = layouts.filter(layout => layout.fields?.placement === 'StylyzeRecs');
    const isCMSDynamicPromo = isCMSDynamicPromoAvailable(cmsEntries);
    let tileSpots = [];
    const productRecsPosition = isTablet || this.isDeviceTypeTablet() ? 21 : 28;
    let productRecsShift = 0;
    if (isCMSDynamicPromo && page === 1) {
      const dynamicTileSpots = getDynamicPromoTileSpots(cmsEntries);
      if (productListMap[dynamicTileSpots?.[0]]?.promoTileIndex !== 0) {
        tileSpots = dynamicTileSpots;
      }
    }

    if (page === 1) {
      const productsCount = productListMap?.length || 0;
      const promoTilesCount = tileSpots?.length || 0;

      tileSpots.forEach((tileSpot, index) => {
        if (productListMap[tileSpot]) {
          productListMap.splice(tileSpot, 0,
            isCMSPromoTile ? <CMSPromoTile index={index} /> : this.renderECMPromoTile(index));
        }
        if( tileSpot < productRecsPosition ) {
          productRecsShift++;
          if( (tileSpot+1)%4 === 0) {
            productRecsShift++
          }
        }
      });

      const extraProdSpots = (productsCount + (promoTilesCount * 2)) % 4;
      const pagesCount = this.getTotalPagesCount();
      const isEmptySpotExist = pagesCount > 1 && (extraProdSpots === 1 || extraProdSpots === 2);
      const isOddTileSpots = promoTilesCount > 0 && promoTilesCount % 2 === 1;
      if(isOddTileSpots && isEmptySpotExist && !isMobile()){
        const tileComponent = isCMSPromoTile ? <CMSPromoTile index={0} /> : this.renderECMPromoTile(0);
        productListMap.push(tileComponent);
      }
    }

    if (showProductRecs && this.props.productList.length > 28) {
      productListMap.splice(productRecsPosition - productRecsShift, 0, (this.getProductRecommendationsResult()));
    }

    return productListMap;
  }

  getMetaDescriptionContent(metaDescription) {
    if (metaDescription !== null) {
      return metaDescription.match('content="([^"]+)"')?.[1];
    } return null;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const oldValue = get(this.props, 'productlistResolved', false);
    const newValue = get(nextProps, 'productlistResolved', false);

    if ((newValue !== oldValue) && newValue) {
      if (this.props.productMetadataToggle && this.props.callMetadataService) {
        this.getProductListMetadata(nextProps.productList);
      }
    }

    if (
      this.props.isDevicetypeMobilePhone &&
      this.props.sysFacetMobileToggle &&
      typeof window !== "undefined" &&
      window.utag_data_dt
    ) {
      let cspToggleValue = this.props.csp_toggle.toString();
      if (reactCookie.load('cCspTogglePath') === 'facet') {
        cspToggleValue = 'false';
      }
      window.utag_data_dt = {
        ...window.utag_data_dt,
        csp_toggle: cspToggleValue
      };
    }

  }

  UNSAFE_componentWillMount() {
    this.ecmPrefetchPlpBanner();
    this.renderSeoPageTitleAndMetaInfo();
  }

  ecmPrefetchPlpBanner() {
    const [categoryId = ''] = get(this.props, 'routeParams.categoryId', '').split('_');
    
  }

  sortPromoTiles() {
    const sortedPromoTiles = [];
    if (!isEmpty(this.props.ecmPromoTile1)) {
      sortedPromoTiles.push(ECMSlots.PLP_PROMO_TILE_1);
    }
    if (!isEmpty(this.props.ecmPromoTile2)) {
      sortedPromoTiles.push(ECMSlots.PLP_PROMO_TILE_2);
    }
    if (!isEmpty(this.props.ecmPromoTile3)) {
      sortedPromoTiles.push(ECMSlots.PLP_PROMO_TILE_3);
    }
    if (!isEmpty(this.props.ecmPromoTile4)) {
      sortedPromoTiles.push(ECMSlots.PLP_PROMO_TILE_4);
    }
    return sortedPromoTiles;
  }

  saveRecentSizesInLocalStorage(existingFilters, currentFilterOptions) {
    if (!isEmpty(currentFilterOptions.Size)) {
      const recentSizeFilters = difference(currentFilterOptions.Size, existingFilters.Size);
      if (!isEmpty(recentSizeFilters)) {
        this.recentSizesStorageHandler.upsertRecentSizesForFilter(reverse(recentSizeFilters));
      }
    }
  }

  updateParamsForFilters(updatedFilters) {
    const { router = {} } = this.props;
    updateParams(router, { filterOptions: JSON.stringify(updatedFilters), page: 1 });
  }

  clearSelectedFacets() {
    this.props.handleClearAllFilters();
    this.props.handleURLUpdate(this.props.router, true);
  }

  isProductListEmpty() {
    return this.props.productList.length === 0;
  }

  hasFilterOptions() {
    const { filterOptions: existingFiltersString } = getParams(this.props.location);
    return !isEmpty(existingFiltersString);
  }

  hasRedirectDetails() {
    return this.props.seo.redirectDetails
      && this.props.seo.redirectDetails.httpCode
      && this.props.seo.redirectDetails.redirectToCategory;
  }

  canonicalUrlsMap() {
    const {isPLP60ToggleEnabled } = this.props;
    const canonicalUrls = this.props.productList.map((product) => product.canonical);
    const offset = (this.props.page - 1) * (isPLP60ToggleEnabled ? PAGE_SIZE_60 : PAGE_SIZE);
    return canonicalUrls.reduce((map, url, index) => {
      map[offset + index] = url;
      return map;
    }, {});
  }

  buildPaginationContext() {
    const {isPLP60ToggleEnabled } = this.props;
    const [categoryId, parentCategoryId = ''] = get(this.props, 'routeParams.categoryId', '').split('_');
    const canonicalUrls = this.canonicalUrlsMap();
    return {
      total: this.props.total,
      pageSize:  (isPLP60ToggleEnabled ? PAGE_SIZE_60 : PAGE_SIZE),
      categoryId,
      parentCategoryId,
      sortBy: this.props.sortBy,
      filterOptions: this.getExistingFilters(),
      canonicalUrls,
    };
  }

  renderECMPromoTile(index) {
    const sortedPromoTiles = this.sortPromoTiles();

    if (!isEmpty(sortedPromoTiles)) {
      return (
        <PromoTile
          key={(index + 1)}
          index={(index + 1)}
          ecmSlot={sortedPromoTiles[index]}
        />
      );
    }

    return null;
  }

  renderSeoPageTitleAndMetaInfo() {
    const titleOverride = unescape(this.props.seo.titleOverride || '');
    const nameOverride = unescape(this.props.seo.nameOverride || '');
    const constructedPageTitle = nameOverride || this.props.title;
    if (titleOverride) {
      this.props.setTitleOverride(titleOverride);
    } else {
      this.props.setTitle(constructedPageTitle, 'at');
    }
    this.props.seo.metaInformation
      ? this.props.setMetaInfoWithHTMLMetaTag(this.props.seo.metaInformation || '')
      : this.props.setMetaDescriptionCategory(constructedPageTitle);
  }

  shouldEnableLazyLoadingForBrowser (browserName, version) {
    if(browserName === "Safari" && Math.floor(parseFloat(version)) < 12) return false;
    return true;
  } 

  renderList(productList) {
    const navPath = isEmpty(getParams(this.props.location).navpath)
      ? this.props.defaultPath
      : getParams(this.props.location).navpath;
    const context = this.buildPaginationContext();
    const contextToBuildMd5 = Object.assign({}, context);
    const {enableLazyLoadingForBrowser} = this.state;
    delete (contextToBuildMd5.canonicalUrls);

    const {
      numberOfProductsToEagerLoad,
      page,
      filterOptions,
      colorOptionsList,
      isUIPRICTest,
      cmsEntries,
      selectedSortBy,
      selectedFilterOptions,
      sortOptions,
      brand,
      countryCode,
      adnlImageToggleEnabled,
    } = this.props;

    const productListMap = productList.map((product, index) => {
      const enableLazyLoading = enableLazyLoadingForBrowser && index >= numberOfProductsToEagerLoad;
      const productSource = product.src && product.src.toLowerCase();
      let productUrl = get(product, 'canonical', `/p/${product.id}`);
      if (countryCode && countryCode !== 'US') {
        productUrl = `/en-${countryCode.toLowerCase()}${productUrl}`
      }
      const msid = product.msid ?? product.sid;
      const adnlImgUrl = matchFristAvalibleShot(product.adnlShots, WEB_ADDITIONAL_IMAGES_ALT_SHOTS);
      return (
        <ConnectedEnhancedProduct
          isPLP
          key={product.id}
          id={product.id}
          page={(page - 1)}
          position={index}
          name={product.name}
          designer={product.designer}
          url={product.main}
          altImage={product.alt}
          cmosItem={product.cmosItem}
          cmosCatalogId={product.cmosCatalog}
          colorOptions={colorOptionsList && colorOptionsList[product.id]}
          productUrl={productUrl}
          focusProductId={get(product, 'focusId', '')}
          dispatch={this.props.dispatch}
          isDisplayAsGroups={this.props.isDisplayAsGroups}
          displayable={product.displayable}
          isFavorite={product.fav}
          filteredColors={filterOptions}
          navPath={navPath}
          applicableDiscount={product.applicableDiscount}
          onlyXItemsLeftStockLevel={
            this.props.onlyXLeftItemsFeatureToggle
            && this.getNoOfItemsLeftInProductIfStockLevelFallsBelowThreshold(product)
          }
          quickLookToggle={this.props.quickLookToggle}
          favoriteToggle={this.props.favoriteToggle}
          enableLazyLoading={enableLazyLoading}
          productMetadata={this.props.productListMetadata}
          productMetadataStatus={this.props.productMetadataStatus}
          source={productSource}
          isGroup={product.grp}
          childProducts={get(product, 'chld', [])}
          hasMoreItems={product.moreItems}
          plpImageSwatchLazyLoadBeta={this.props.plpImageSwatchLazyLoadBeta}
          plpImageLazyLoadBeta={this.props.plpImageLazyLoadBeta}
          moveProductTogglesToList={this.props.moveProductTogglesToList}
          node={this.state.node}
          isDevicetypeDesktop={this.props.isDevicetypeDesktop}
          hasMoreSwatches={product.moreSwatches}
          oneSwatch={product.oneSwatch}
          flag={product.flg}
          flagType={product.flgType}
          retail={product.rprc}
          original={product.oprc}
          adornLabel={product.adornTxt}
          adornColor={product.adColor}
          promoText={product.promoTxt}
          promoColor={product.promoColor}
          dogEarColor={product.dogEar}
          advertisePromos={get(product, 'advPromos', [])}
          promoPrice={product.pprc}
          mca={product.mca}
          isUIPRICTest={isUIPRICTest}
          showAltAnimation={this.state.showAltAnimation}
          showESGLabel = {get(product, 'showESGLabel', false)}
          groupProductPriceRange = {get(product, 'groupProductPriceRange', null)}
          defaultColorName={get(product, 'clrName', '')}
          msid={msid}
          adnlImage={adnlImgUrl}
          adnlImageToggleEnabled={adnlImageToggleEnabled}
        />
      );
    });
    const [defaultSortByOption] = Object.keys(sortOptions.find(({ isDefault }) => isDefault === 'true') || {});
    const isCMSPromoTile = (isCMSPromoAvailable(cmsEntries) || isCMSDynamicPromoAvailable(cmsEntries));
    const isInitialPage = page === 1 && isEqual(selectedSortBy, defaultSortByOption)
      && (isString(selectedFilterOptions)
        || Object.entries(omit(selectedFilterOptions, IN_STORE))?.every(([key, value]) => isEmpty(value))
      );

    if (!isInitialPage) {
      return productListMap;
    }

    let getItFastSelected = false;
    if (selectedFilterOptions && typeof(selectedFilterOptions) === 'object' && !isEmpty(selectedFilterOptions) && brand !== 'HC') {
      const getItFast = cloneDeep(selectedFilterOptions[IN_STORE]);
      if (getItFast) {
        getItFast.shift();
        getItFastSelected = getItFast.some(e => e);
      }
    }

    const { seoToggle, router = {} } = this.props;
    const locationQueryString = router.location?.search?.split("?")[1];
    const selectedFiltersUtag = seoSelectedFilter(this.props, locationQueryString);
    if ((seoToggle && values(selectedFiltersUtag).some(some)) || getItFastSelected) {
      return productListMap;
    } else {
      if (isCMSPromoTile) {
        return this.getProductListWithInsertedPromoTilesFromCRP(productListMap, isCMSPromoTile)
          || productListMap;
      }
      return this.getProductListWithPromoTiles(productListMap, isCMSPromoTile) || productListMap;
    }
  }

  renderLoadingPlaceholders() {
    return this.props.isDevicetypeMobilePhone
      ? times(this.props.numOfProductsOnMobile,
        () => <ProductLoadingPlaceholder animate={this.props.animateSkeletonToggle} />)
      : times(this.props.numOfProductsOnDesktop,
        () => <ProductLoadingPlaceholder animate={this.props.animateSkeletonToggle} />);
  }

  render() {
    const [categoryId = ''] = get(this.props, 'routeParams.categoryId', '').split('_');
    
    const {
      isDevicetypeMobilePhone,
      skeletonLoadingToggle,
      fetchingProducts,
      seoToggle,
      seoFacets,
      cmsGlobalContent,
      cmsContent,
      templateDetails,
      seoFacetsMap,
      applicableFilters,
      isInStoreFacetAvailable,
      recentlyViewedProducts,
      recentlyViewedToggle,
      recentlyViewedAbTest,
      graphiteHQRelatedLinks,
      graphiteHQRelatedProducts,
      relatedLinksToggle,
      relatedProductsToggle,
      device,
      router = {},
      seoCopyToggle,
      isDomestic,
      seoProductRecCarouselAbTest,
      isRebrandNMToggleOn,
      nmSeoCopyToggle,
      loadFoundIt,
    } = this.props;
    if (this.hasRedirectDetails()) {
      return null;
    }
    const locationQueryString = router.location?.search?.split("?")[1];
    const selectedFilter = seoSelectedFilter(this.props, locationQueryString);
    const filtersApplied = isFilterApplied(selectedFilter, seoFacetsMap, applicableFilters);
    const hideBottomSEO = seoToggle && seoFacets?.length > 0 && filtersApplied;
    const showSeoCopyDesktop = !isDevicetypeMobilePhone && !hideBottomSEO && isDomestic && seoCopyToggle;

    if (this.isProductListEmpty() && !this.hasFilterOptions()) {
      return (
        <div className="grid-80 tablet-grid-80 mobile-grid-100">
          <h1 className="product-list__error-message">
            {`${PRODUCTS_NOT_AVAILABLE_MESSAGE} ${unescape(this.props.title || '')}`}
          </h1>
          <div
            className="product-list__no-results-content"
            dangerouslySetInnerHTML={dangerousProperty(this.props.categoryNoResultsContent, [], { a: ['href'] })}
          />
        </div>
      );
    }

    const { templateType, boutiqueChild } = templateDetails;
    const shouldHideGlobalBanner = templateType === 'P' && boutiqueChild;
    const getBottomBanner = () => {
      if (placementExists(cmsContent, 'Bottom')) {
        return renderCMSContent(cmsContent, 'Bottom');
      }

      if (isGlobalBottomBanner(cmsGlobalContent) && !shouldHideGlobalBanner) {
        return renderCMSContent(cmsGlobalContent, 'Bottom');
      }

      return null;
    };

    const getRecentlyViewedProductsOnPlp = () => {
      if(recentlyViewedToggle || recentlyViewedAbTest){
        return renderRecentlyViewedProducts(recentlyViewedProducts);
      }
      return null;
    }

    const getRelatedCategoriesOnPlp = () => {
      if(relatedLinksToggle && !isEmpty(graphiteHQRelatedLinks)){
        return <RenderRelatedCategories relatedCategories={graphiteHQRelatedLinks} device={device}/>;
      }
      return null;
    }

    const getRelatedProductsOnPlp = () => {
      if(relatedProductsToggle && !isEmpty(graphiteHQRelatedProducts)){
        if(!seoProductRecCarouselAbTest){
          return <RenderRelatedProducts relatedProducts={graphiteHQRelatedProducts} device={device}/>
        }
      }
      return null;
    }

    return (
      <div className={classnames('product-list-wrapper', { 'rebrand-wrapper-style': isRebrandNMToggleOn })}>
        <div className="grid-20 tablet-grid-25" id="product-list__facets-spacer">
          <img alt="facets spacer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
        </div>
        <StickyScroll
          isDevicetypeMobilePhone={isDevicetypeMobilePhone}
        >
          <div
            className="grid-80 tablet-grid-75 mobile-grid-100 grid-parent plp-grid-large"
            id="scroll-frame"
          >
            <span className="invisible" id="ada-sortBy-label">Sort Dropdown</span>
            <span className="invisible" id="ada-sortBy-description">Select a sort option to sort products in this category.</span>
            <ConnectedProductListHeader
              onFilterChange={this.getProductsForSelectedFilters}
              onChange={this.getProductsForSelectedSortOption}
              onClearFilters={this.clearSelectedFacets}
              categoryId={categoryId}
              isInStoreFacetAvailable={isInStoreFacetAvailable}
              loadFoundIt={loadFoundIt}
            />
            {seoToggle && (
              <Helmet>
                <title>
                  {this.props.seo.titleOverride
                    ? unescape(this.props.seo.titleOverride || '')
                    : unescape(this.props.seo.title || '')}
                </title>
                <meta
                  name="description"
                  content={this.getMetaDescriptionContent(
                    this.props.seo.metaInformation
                  )}
                />
              </Helmet>
            )}
            <div
              className={classnames(
                'product-list grid-100 tablet-grid-100 mobile-grid-100',
                { 'align-images': isDevicetypeMobilePhone },
              )}
            >

              {
                (skeletonLoadingToggle && fetchingProducts)
                  ? this.renderLoadingPlaceholders()
                  : this.renderList(this.props.productList)
              }

            </div>
            <div className="grid-100 tablet-grid-100 mobile-grid-100">
              <Pagination source="PLP" isListEnd={true} />
              {getBottomBanner()}
              {isDevicetypeMobilePhone && getRecentlyViewedProductsOnPlp()}
              {isDevicetypeMobilePhone && isDomestic && getRelatedProductsOnPlp()}
              {isDevicetypeMobilePhone && isDomestic && getRelatedCategoriesOnPlp()}
              {!nmSeoCopyToggle && !hideBottomSEO && !showSeoCopyDesktop && this.getSeoContent()}
            </div>
          </div>
        </StickyScroll>

         {!isDevicetypeMobilePhone && getRecentlyViewedProductsOnPlp()}
         {!isDevicetypeMobilePhone && isDomestic && getRelatedProductsOnPlp()}
         {!isDevicetypeMobilePhone && isDomestic && getRelatedCategoriesOnPlp()}

        <RelatedItems />

        {!(nmSeoCopyToggle && seoCopyToggle) && showSeoCopyDesktop && this.getSeoContent()}
      </div>
    );
  }
}

const filterOptionsSelector = createSelector(
  (state) => state.routing,
  (routing) => {
    const filterOptions = get(routing, 'locationBeforeTransitions.query.filterOptions', []);
    let setFilterOptions = {};
    if (filterOptions) {
      try {
        setFilterOptions = JSON.parse(filterOptions);
      } catch (e) {
        setFilterOptions = {};
      }
    }
    return setFilterOptions;
  }
);

function mapStateToProps(state, ownProps) {
  const productlistResolved = get(state, 'api.product_list.resolved');
  const productlistLoading = get(state, 'api.product_list.loading');
  const tile1Resolved = get(state, 'api.ecmplppromotile1.resolved');
  const tile2Resolved = get(state, 'api.ecmplppromotile2.resolved');
  const tile3Resolved = get(state, 'api.ecmplppromotile3.resolved');

  let ecmPromoTile1;
  let ecmPromoTile2;
  let ecmPromoTile3;
  let ecmPromoTile4;

  if (tile1Resolved && tile2Resolved && tile3Resolved) {
    ecmPromoTile1 = get(state.ecm, 'ecmContent.plpPromoTile1');
    ecmPromoTile2 = get(state.ecm, 'ecmContent.plpPromoTile2');
    ecmPromoTile3 = get(state.ecm, 'ecmContent.plpPromoTile3');
    ecmPromoTile4 = get(state.ecm, 'ecmContent.plpPromoTile4');
  }

  const adnlImageAbTestToggle = get(state, 'toggles.ABTEST_ADDITIONAL_IMAGES', false);
  const adnlImageAbTestParam =  get(state, `abTestsOpt.${ABTEST_ADDITIONAL_IMAGES}.variation`, 'a') === 'b';
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  
  return {
    productlistResolved,
    productlistLoading,
    ecmPromoTile1,
    ecmPromoTile2,
    ecmPromoTile3,
    ecmPromoTile4,
    colorOptionsList: state.productListPage.products.colorOptionsList,
    productList: state.productListPage.products.list,
    isDisplayAsGroups: get(state, 'productListPage.products.isDisplayAsGroups', false),
    total: state.productListPage.products.total,
    page: state.productListPage.products.currentPage,
    title: state.productListPage.products.title,
    isNewArrivalCategory: state.productListPage.products.isNewArrivalCategory,
    categoryNoResultsContent: state.productListPage.categoryNoResultsContent,
    seo: state.productListPage.seo,
    applicableFilters: state.productListPage.products.applicableFilters,
    sortBy: state.productListPage.products.selectedSortOption,
    jsessionID: state.session.JSESSIONID,
    breadcrumbs: state.navigation.breadcrumbs,
    leftNavigationId: state.leftNavigation.id,
    filterOptions: filterOptionsSelector(state),
    onlyXLeftThresholds: state.onlyXLeftThresholds,
    onlyXLeftItemsFeatureToggle: state.toggles.PLP_ONLY_X_LEFT,
    quickLookToggle: state.toggles.QUICK_LOOK,
    numberOfProductsToEagerLoad: ownProps.numberOfProductsToEagerLoad || 12,
    productMetadataStatus: state.api.product_list_metadata,
    productListMetadata: state.productListPage.productListMetadata,
    productMetadataToggle: state.toggles.SHOW_PRODUCT_METADATA_PLP,
    favoriteToggle: state.toggles.PLP_FAVORITES,
    callMetadataService: get(state.session, 'callMetadataService', false),
    plpImageSwatchLazyLoadBeta: get(state.toggles, 'PLP_IMAGE_SWATCH_LAZY_LOAD_BETA', false),
    plpImageLazyLoadBeta: get(state.toggles, 'PLP_IMAGE_LAZY_LOAD_BETA', false),
    moveProductTogglesToList: get(state.toggles, 'MOVE_PRODUCT_TOGGLES_TO_LIST', false),
    isCrpOn: state.toggles.CRP,
    cmsContent: get(state, 'cms.entries[0]', {}),
    device: state.device,
    isTablet: state.device.isTablet,
    isDevicetypeDesktop: state.device.isDesktop,
    isDevicetypeMobilePhone: state.device.isMobilePhone,
    defaultPath: get(state, 'templates.templateDetails.defaultPath', ''),
    skeletonLoadingToggle: state.toggles.SKELETON_LOADING,
    animateSkeletonToggle: state.toggles.ANIMATE_SKELETON,
    fetchingProducts: state.spinner.active,
    numOfProductsOnMobile: get(state, 'mobileConfig.pagination.fetchSize', 60),
    numOfProductsOnDesktop: 120,
    isUIPRICTest: get(state.abTestsOpt, 'UIPRIC.variation', 'a') === 'b',
    cmsEntries: get(state, 'cms.entries', []),
    selectedSortBy: state.productListPage?.selectedSortBy,
    selectedFilterOptions: state.productListPage?.selectedFilterOptions,
    sortOptions: state.productListPage?.products?.sortOptions || [],
    seoToggle: get(state.toggles, 'SEO_BUCKET_ONE', false),
    seoFacets: state.facetedLeftNav?.seoFacets,
    seoFacetsMap: state.facetedLeftNav?.seoFacetsMap,
    cmsGlobalContent: get(state, 'cms.global[0]', {}),
    templateDetails: get(state, 'templates.templateDetails', {}),
    csp_toggle: get(state, `facetedLeftNav.facetedFiltersList['In Store'].csp`, false),
    sysFacetMobileToggle: get(state.toggles, 'SYSFACET_MOBILE', false),
    cspTogglePath: get(state, `facetedLeftNav.cspTogglePath`, ''),
    brand: state.brand_name?.env,
    countryCode: get(state, 'locale.countryCode', 'US'),
    altImageAbTestToggle: get(state, `abTestsOpt.${ABTEST_MOBILE_ALT_IMAGES}.variation`, 'a') === 'b',
    recentlyViewedProducts: get(state, `productCatalog.recentlyViewedProducts`, []),
    recentlyViewedToggle: get(state.toggles, 'RECENTLY_VIEW_PLP', false),
    recentlyViewedAbTest: get(state.abTestsOpt, 'nmrv0002.variation', 'a') === 'b',
    graphiteHQRelatedLinks: get(state, `productCatalog.graphiteHQRelatedLinks`, []),
    relatedLinksToggle: get(state.toggles, 'IL_API_PLP', false),
    graphiteHQRelatedProducts: get(state, `productCatalog.graphiteHQRelatedProducts`, []),
    relatedProductsToggle: get(state.toggles, 'IL_API_PDP', false),
    seoCopyToggle: get(state.toggles, 'SEO_COPY', false),
    isDomestic,
    seoProductRecCarouselAbTest: get(state, 'toggles.ABTEST_PLP_SEO_REC', false) &&
      get(state, `abTestsOpt.${ABTEST_PLP_SEO_REC}.variation`, 'a') === 'b',
    adnlImageToggleEnabled: adnlImageAbTestToggle && adnlImageAbTestParam,
    isRebrandNMToggleOn: get(state, 'toggles.NM_REBRAND_2023', false),
    exposedPaginationToggle: get(state, 'toggles.EXPOSE_PAGINATION', false),
    nmSeoCopyToggle: isDomestic && get(state, 'toggles.NM_SEO_COPY', false),
    showProductRecs: get(state.toggles, 'PRODUCT_RECS_PLP', false) || (get(state, 'toggles.AB_TEST_PRODUCT_RECS_PLP', false) &&
    get(state, `abTestsOpt.tl329.variation`, 'a') === 'b') || (get(state, 'toggles.AB_TEST_PRODUCT_RECS_BOUTIQUE', false) && get(state, `abTestsOpt.tl329-2.variation`, 'a') === 'b'),
    isPLP60ToggleEnabled: (get(state, 'toggles.AB_TEST_PLP_60', false) && get(state, `abTestsOpt.tl357.variation`, 'a') === 'b') ,
  };
}

const mapDispatchToProps = ({
  getECMRequest,
  getCategoryNoResultsContent,
  getProductListMetaData,
  handleClearAllFilters,
  handleURLUpdate,
  setMetaDescriptionCategory,
  setFullMetaDescription,
  setTitle,
  setTitleOverride,
  setMetaInfoWithHTMLMetaTag,

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductList));

